import React from "react";
import { Button, Modal } from "@material-ui/core";
import { withTrans } from "../../i18n/withTrans";
import { useHandleResize } from "../../utils";

const CookiesModal = ({ t, open, setOpen }) => {
  const onClose = () => {
    setOpen(!open);
    localStorage.setItem("isCookiesAccepted", "true");
  };

  const { width } = useHandleResize();

  const style = {
    position: "absolute",
    bottom: width < 600 ? "1vh" : "5%",
    left: width < 600 ? "50%" : 24,
    transform: width < 600 ? "translateX(-50%)" : "unset",
    width: width < 520 ? "95vw" : 480,
    bgcolor: "background.paper",
    boxShadow: 24,
    pt: 2,
    px: 2,
    pb: 3,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    padding: width < 400 ? 20 : 30,
    boxSizing: "border-box",
    outline: 0,
    outlineStyle: "none",
  };

  return (
    <Modal
      disableEscapeKeyDown
      open={open}
      onClose={() => onClose()}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <div style={style}>
        <h2 className="modal-title">{t("cookies.title")}</h2>
        {/* <div style={{ position: "absolute", right: 10, top: 10 }}>
          <IconButton onClick={onClose}>
            <MdClear />
          </IconButton>
        </div> */}
        <p>{t("cookies.description")}</p>
        <div style={{ display: "flex", justifyContent: "end", marginTop: 16 }}>
          {/*<Button onClick={() => onClose()} style={{ fontSize: 12 }}>
            {t("cookies.refuse")}
          </Button>*/}
          <Button
            onClick={() => onClose()}
            style={{
              background: "#000",
              color: "white",
              borderRadius: 0,
              marginLeft: 8,
            }}
            variant="contained"
          >
            {t("cookies.accept")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default withTrans(CookiesModal);
