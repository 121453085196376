import React, { useEffect, useState } from 'react';
import { withTrans } from '../../i18n/withTrans';
import { Divider, Drawer, IconButton } from '@material-ui/core';
import { AiOutlineRight } from 'react-icons/ai';
import CartList from './CartList';
import fullTicketImg from '../../assets/images/ticket-full.jpg';
import reducedTicketImg from '../../assets/images/ticket-reduced.jpg';
import childTicketImg from '../../assets/images/ticket-child.jpg';
import freeTicketImg from '../../assets/images/ticket-free.jpg';

const styles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start'
};

const isBrowser = typeof window !== 'undefined';

const CartListDrawer = ({ t, open, setOpen }) => {
  const [state, dispatch] = useState({
    activeStep: 2,
    totalPrice: 0,
    ticketsList: []
  });
  let oldReservation = {};

  if (isBrowser) {
    oldReservation = localStorage.getItem('panier');
  }

  const setTicketImg = (visitorType) => {
    let ticketImg = '';
    switch (visitorType) {
      case 'full_price':
        ticketImg = fullTicketImg;
        break;
      case 'reduced_price':
        ticketImg = reducedTicketImg;
        break;
      case 'child_price':
        ticketImg = childTicketImg;
        break;
      case 'free_price':
        ticketImg = freeTicketImg;
        break;
      default:
        ticketImg = fullTicketImg;
        break;
    }
    return ticketImg;
  };

  useEffect(() => {
    let total = 0;
    const old = JSON.parse(oldReservation);
    if (old) {
      (old?.ticketsList || []).forEach((ticket) => {
        total += ticket.price;
      });
    }

    dispatch({
      ...state,
      oldReservation: old,
      totalPrice: total.toFixed(2)
    });
  }, [oldReservation]);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Drawer
        sx={{
          width: 200,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 200
          }
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <div className="cartListDrawer">
          <div style={styles}>
            <IconButton onClick={handleDrawerClose}>
              <AiOutlineRight />
            </IconButton>
            <h2 style={{ color: 'rgba(0, 0, 0, 0.8)' }} className="cart-title">
              {t('myOrderSocket')}
            </h2>
          </div>
          <Divider />
          <CartList
            purpose="drawer"
            setTicketImg={setTicketImg}
            reservationState={state}
            reservationDispatch={dispatch}
          />
        </div>
      </Drawer>
    </>
  );
};

export default withTrans(CartListDrawer);
