import React from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextareaAutosize,
  TextField
} from '@material-ui/core';
import { CheckBox } from '@material-ui/icons';
import { useGoogleReCaptcha, GoogleReCaptcha } from 'react-google-recaptcha-v3';

const ContactGroupForm = ({ t, state, dispatch, textBox }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  /* useEffect(() => {
            (async () => {
                const test = await executeRecaptcha()
                console.log('executeRecaptcha', test)
            })();
        }, []);
*/
  const validationEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  };

  const validatePhone = (phone) => {
    let check = false;
    const phoneTest =
      /^(?:(?:\+|00)?([0-9]{0,3})|0)\s*[1-9](?:[\s.-]*\d{2}){4,5}$/;
    if (phone.match(phoneTest)) {
      check = true;
    }

    return check;
  };

  const handleInputChange = (name) => (event) => {
    let email_validation = '';
    let email = '';
    const errors = [];
    const { value } = event.target || { target: {} };

    switch (name) {
      case 'email':
        email = value;
        dispatch({
          contact: {
            ...(state.contact || {}),
            email: value
          }
        });
        if (!validationEmail(value)) {
          errors.push('email_format');
        }
        break;
      case 'email_validation':
        email_validation = value;
        dispatch({
          contact: {
            ...(state.contact || {}),
            email_validation: value
          }
        });
        break;
      case 'messageObject':
        dispatch({
          contact: {
            ...(state.contact || {}),
            messageObject: value
          }
        });
        break;
      case 'prenom':
        dispatch({
          contact: {
            ...(state.contact || {}),
            prenom: value
          }
        });
        break;
      case 'nom':
        dispatch({
          contact: {
            ...(state.contact || {}),
            nom: value
          }
        });
        break;
      case 'tel':
        dispatch({
          contact: {
            ...(state.contact || {}),
            telephone: value
          }
        });
        if (!validatePhone(value)) {
          errors.push('phone_format');
        }
        break;
      default:
        break;
    }

    if (
      state.contact.email !==
      (email_validation || state.contact.email_validation)
    ) {
      errors.push('email_validation');
    } else if (state.contact.email === email_validation) {
      errors.filter((el) => el !== 'email_validation');
    }
    dispatch({
      errors_validation: errors
    });
  };

  const handleChangeText = (value) => {
    dispatch({
      contact: {
        ...state.contact,
        messageBody: value
      }
    });
  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' }
      }}
      noValidate
      autoComplete="off"
      className="visitor-form-wrapper"
    >
      <FormControl style={{ margin: 0 }}>
        <div
          style={{
            width: '90%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingLeft: 20
          }}
        >
          <div className="visitor-form-row">
            <TextField
              required
              error={
                state.errors_validation?.includes('contact') &&
                !state.contact.prenom
              }
              label={t('visitors.form.first_name')}
              onChange={handleInputChange('prenom')}
              value={state.contact.prenom || ''}
            />
            <TextField
              required
              error={
                state.errors_validation?.includes('contact') &&
                !state.contact.nom
              }
              label={t('visitors.form.last_name')}
              onChange={handleInputChange('nom')}
              value={state.contact.nom || ''}
            />
          </div>
          <div className="visitor-form-row">
            <TextField
              required
              error={
                (state.errors_validation?.includes('contact') &&
                  !state.contact.email) ||
                state.errors_validation?.includes('email_format')
              }
              helperText={
                (state.errors_validation || []).includes('email_format') &&
                t('errors.email_format')
              }
              label={t('visitors.form.email')}
              onChange={handleInputChange('email')}
              value={state.contact.email || ''}
            />
            <TextField
              required
              label={t('visitors.form.confirm_email')}
              onChange={handleInputChange('email_validation')}
              error={state?.errors_validation?.includes('email_validation')}
              value={state.contact.email_validation || ''}
            />
          </div>
          <div className="visitor-form-row">
            <Grid container>
              <Grid item xs={8}>
                <TextField
                  placeholder={t('visitors.form.free_field')}
                  label={t('visitors.form.phone')}
                  helperText={
                    state.errors_validation?.includes('phone_format') &&
                    t('errors.phone_format')
                  }
                  error={state.errors_validation?.includes('phone_format')}
                  onChange={handleInputChange('tel')}
                  value={state.contact.telephone || ''}
                />
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>
          </div>
          {textBox && (
            <div className="contact-textarea">
              <TextField
                style={{ width: '100%' }}
                label={t('contactForm.messageObject')}
                error={
                  state.errors_validation?.includes('contact') &&
                  !state.contact.email
                }
                onChange={handleInputChange('messageObject')}
                value={state.contact.messageObject || ''}
              />
              <TextareaAutosize
                minRows={4}
                onChange={(event) => handleChangeText(event.target.value)}
                placeholder={t('contactForm.messageBody')}
                style={{
                  fontSize: 16,
                  fontFamily: 'Whyte',
                  width: '100%',
                  marginTop: 10,
                  borderTop: 'none',
                  borderLeft: 'none',
                  borderRight: 'none'
                }}
              />
            </div>
          )}

          <div className="visitor-form-row">
            <GoogleReCaptcha
              onVerify={(token) => {
                dispatch({ captchaToken: token });
              }}
            />
          </div>
          <div
            className="visitor-form-row"
            style={{
              color: 'rgba(0, 0, 0, 0.7)',
              justifyContent: 'start',
              marginBottom: 32
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  style={{ position: 'relative', top: -2, marginRight: 4 }}
                  checked={state.newsletter}
                  onClick={() => dispatch({ newsletter: !state.newsletter })}
                />
              }
              label={
                <span className="newsletter">
                  {t('visitors.form.accept_newsletter')}
                </span>
              }
            />
          </div>
        </div>
        <p className="mandatory-fields-text">
          {t(`visitors.form.mandatory_fields`)}
        </p>
      </FormControl>
    </Box>
  );
};

export default ContactGroupForm;
