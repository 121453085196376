import React from "react";
import { IconButton, List, ListItem, Modal } from "@material-ui/core";
import { navigate } from "gatsby";
import { MdClear } from "react-icons/md";
import { useHandleResize } from "../../utils";

const CopyrightModal = ({ open, setOpen }) => {
  const onClose = () => {
    setOpen(!open);
  };
  const copyright = [
    "Powered by Strateg.In",
    "Kyle Head on Unsplash",
    "Alberto Bigoni on Unsplash",
    "Leon Liu on Unsplash",
    "Ricardo Moura on Unsplash",
    "Kiko Photos / Shutterstock.com",
    "Stockbym / Shutterstock.com",
    "Pack-Shot / Shutterstock.com",
    "Anton_Ivanov / Shutterstock.com",
    "Tang Yan Song / Shutterstock.com",
  ];

  const { width, height } = useHandleResize();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width < 320 ? "95vw" : 300,
    height: height < 530 ? "95vh" : "fit-content",
    bgcolor: "background.paper",
    boxShadow: 24,
    pt: 2,
    px: 2,
    pb: 3,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    padding: 30,
    boxSizing: "border-box",
    overflowX: "hidden",
    overflowY: "auto",
    outline: 0,
    outlineStyle: "none",
  };

  return (
    <Modal
      disableEscapeKeyDown
      open={open}
      onClose={() => onClose()}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <div style={style}>
        <h2 className="modal-title">Copyright</h2>
        <div style={{ position: "absolute", right: 10, top: 10 }}>
          <IconButton onClick={onClose}>
            <MdClear />
          </IconButton>
        </div>
        <List>
          {copyright.map((el) => (
            <ListItem>{el}</ListItem>
          ))}
        </List>
      </div>
    </Modal>
  );
};

export default CopyrightModal;
