import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import {MenuItem, Select} from "@material-ui/core";
import * as moment from "moment";

const LanguageMenu = ({open, handleOpen, handleClose, indexStep, pageName, ...props}) => {
    const { i18n } = useTranslation()

    const [language, setLanguage] = useState(i18n.language === 'fr-FR' ? 'fr' : i18n.language);

    function handleChange(event) {
        i18n.changeLanguage(event.target.value);
        moment.locale(event.target.value)

        setLanguage(event.target.value);
    }

    const setColor = (pageName) => {
        let color = '';
        switch (pageName) {
            case 'home':
                color = '#fff';
                break;
            case 'reservation_page':
                color = '#fff';
                break;
            case 'slot_selection': {
                if (indexStep === 2 || indexStep === 3) {
                    color = '#fff'
                } else {
                    color = '#000';
                }
            }
                break;
            default:
                color = '#000'
                break;
        }
        return color;
    }

    return (
        <Select
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            style={{color: setColor(pageName) }}
            value={language}
            onChange={(e) => handleChange(e)}
            disableUnderline
            inputProps={{
                name: 'language'
            }}
        >
            <MenuItem value={'fr'}>FR</MenuItem>
            <MenuItem value={'en'}>EN</MenuItem>
            <MenuItem value={'es'}>ES</MenuItem>
        </Select>
    )
}

export default LanguageMenu;
